import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../index.css";
import LanguageContext from "../contexts/LanguageContext";
import PlaceContext from "../contexts/PlaceContext";
import { MdFileDownloadDone } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Montenegro from "../photos/montenegro.svg";
import Serbia from "../photos/serbia.svg";
import Slovenia from "../photos/slovenia.svg";
import Albania from "../photos/albania.svg";
import {
  ArabicFlag,
  AustriaFlag,
  BosnianFlag,
  CroatianFlag,
  DutchFlag,
  EnglishFlag,
  FranceFlag,
  GermanFlag,
  ItalianFlag,
  SpanishFlag,
  TurkishFlag,
  AmericanFlag,
} from "../utils/flags/CircleFlags";
import CloseAdd from "../photos/close-x.svg";

export default function HomeMenu() {
  const { place, setPlaceId } = useContext(PlaceContext);
  const [waiterLogged, setwaiterLogged] = useState(
    localStorage.getItem("waiterLogged")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const placeId = useParams().id;

  if (place?.id === parseInt(placeId)) {
    localStorage.setItem("placeId", placeId);
  } else {
    localStorage.removeItem("placeId");
    localStorage.setItem("placeId", placeId);
    localStorage.removeItem("shoppingCart");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let mainCategories = [];

  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const navigate = useNavigate();
  const language = useContext(LanguageContext);
  const [showLogin, setShowLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [timer, setTimer] = useState();

  const languages = [
    {
      name: "Bosanski",
      img: <BosnianFlag />,
      langKey: "bs",
    },
    {
      name: "English",
      img: <EnglishFlag />,
      langKey: "en",
    },
    {
      name: "Deutsch",
      img: <GermanFlag />,
      langKey: "de",
    },
    {
      name: "Español",
      img: <SpanishFlag />,
      langKey: "es",
    },
    {
      name: "Français",
      img: <FranceFlag />,
      langKey: "fr",
    },
    {
      name: "Italiano",
      img: <ItalianFlag />,
      langKey: "it",
    },
    {
      name: "Türkçe",
      img: <TurkishFlag />,
      langKey: "tr",
    },
    {
      name: "العربية",
      img: <ArabicFlag />,
      langKey: "sa",
    },
    {
      name: "Srpski",
      img: (
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: "100px",
            objectFit: "cover",
          }}
          src={Serbia}
        />
      ),
      langKey: "rs",
    },
    {
      name: "Српски",
      img: (
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: "100px",
            objectFit: "cover",
          }}
          src={Serbia}
        />
      ),
      langKey: "sr",
    },
    {
      name: "Hrvatski",
      img: <CroatianFlag />,
      langKey: "hr",
    },
    {
      name: "Crnogorski",
      img: (
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: "100px",
            objectFit: "cover",
          }}
          src={Montenegro}
        />
      ),
      langKey: "me",
    },
    {
      name: "Shqip",
      img: (
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: "100px",
            objectFit: "cover",
          }}
          src={Albania}
        />
      ),
      langKey: "al",
    },
    {
      name: "Slovenski",
      img: (
        <img
          style={{
            width: 25,
            height: 25,
            borderRadius: "100px",
            objectFit: "cover",
          }}
          src={Slovenia}
        />
      ),
      langKey: "sl",
    },
    {
      name: "Nederlands",
      img: <DutchFlag />,
      langKey: "nl",
    },
    {
      name: "Deutsch",
      img: <AustriaFlag />,
      langKey: "at",
    },
    {
      name: "English",
      img: <AmericanFlag />,
      langKey: "en_us",
    },
  ];

  useEffect(() => {
    setPlaceId(placeId);
    const today = new Date();
    localStorage.setItem("storedDate", today.toISOString());
    const storedDate = localStorage.getItem("storedDate");
    const storedDateObj = new Date(storedDate);
    const isToday = storedDateObj.toDateString() === today.toDateString();

    if (!isToday) {
      localStorage.removeItem("shoppingCart");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (place?.ads?.random_ad !== null || place?.adsense_code?.length !== 0) {
      setIsModalOpen(true);
    }
  }, [place]);

  useEffect(() => {
    function updateLocalStorage() {
      localStorage.setItem("mainCategories", JSON.stringify(mainCategories));
    }
    updateLocalStorage();
  }, [mainCategories]);

  function handleClick(lang) {
    language.setLanguage(lang);
    localStorage.setItem("dataKey", lang);
    navigate(`/menu/${place.id}/categories`);
    const localPlace = JSON.stringify(place);
    localStorage.setItem("place", localPlace);
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submitPassword = async () => {
    setShowLogin(!showLogin);

    if (password === place.password) {
      setwaiterLogged("true");
      localStorage.setItem("waiterLogged", "true");
      toast("Successfully logged in", { type: "success" });
    }
  };

  const showPasswordInput = () => {
    setShowLogin(!showLogin);
    setPassword("");
  };

  const waiterLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to log out. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setPassword("");
      localStorage.removeItem("waiterLogged");
      setwaiterLogged();
    }
  };

  const postIncrementAd = async (ad_id, place_id) => {
    try {
      const response = await fetch(
        `https://qrmenudev.podrska.ba/api/ads/${ad_id}/increment/${place_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Error incrementing ad:", error);
    }
  };

  const handleAdClick = async (adId, placeId, redirectUrl) => {
    const url = `/api/ads/${adId}/increment/clicked/${placeId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setTimeout(() => {
        window.open(redirectUrl, "_blank");
      }, 100);
    } catch (error) {
      console.error("Error incrementing ad click:", error);
    }
  };

  const handleImageLoad = () => {
    // const token = process.env.REACT_APP_API_TOKEN;
    if (isModalOpen && place?.ads?.random_ad?.media?.url) {
      setIsImageLoaded(true);
      postIncrementAd(place?.ads?.random_ad?.id, place?.id).catch((error) => {
        console.error("Error incrementing ad:", error);
      });
    } else if (isModalOpen && place?.adsense_code) {
      setIsImageLoaded(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const closeModal = (e) => {
    e.stopPropagation(); // Stop the event from propagating to the parent div
    setIsModalOpen(false);
  };

  // All this because of Cajo's en-us instead of en_us
  const normalizeLanguageKey = (key) => key.replace(/-/g, "_");

  const enabledLanguages = place.enabled_languages || [];
  const normalizedEnabledLanguages = enabledLanguages.map(normalizeLanguageKey);
  // Sorting languages array based on the index from mapping
  const sortedLanguages = languages
    .filter((lang) => normalizedEnabledLanguages.includes(lang.langKey))
    .sort(
      (a, b) =>
        normalizedEnabledLanguages.indexOf(a.langKey) -
        normalizedEnabledLanguages.indexOf(b.langKey)
    );

  const enabledPlaceLanguages = place.enabled_languages
    ? place.enabled_languages.map(normalizeLanguageKey)
    : [];

  useEffect(() => {
    if (isImageLoaded && place?.ads?.random_ad?.timer > 0) {
      setTimer(place?.ads?.random_ad?.timer);
      const timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(timerInterval);
            setIsModalOpen(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [isImageLoaded]);

  return (
    <div
      style={{
        backgroundImage: `url(${place.background_image})`,
        backgroundSize: "cover",
        position: "relative",
        maxHeight: "100vh",
        overflow: `${isModalOpen ? "hidden" : "scroll"}`,
      }}
    >
      {place &&
      place?.ads?.random_ad !== null &&
      place?.ads?.random_ad?.media?.url !== null &&
      isModalOpen ? (
        <div
          className="ads-modal"
          // style={{
          //   backgroundImage: `url(${place?.ads?.random_ad?.image})`,
          //   backgroundSize: "cover",
          //   height: "100%",
          //   width: "100%",
          //   borderRadius: "35px",
          // }}
          onClick={
            place?.ads?.random_ad?.redirect_url
              ? (e) => {
                  e.preventDefault();
                  handleAdClick(
                    place?.ads?.random_ad?.id,
                    place?.id,
                    place?.ads?.random_ad?.redirect_url
                  );
                }
              : (e) => e.preventDefault()
          }
        >
          {isImageLoaded &&
            (timer === 0 || timer == undefined ? (
              <div onClick={closeModal} className="close-ads">
                <img style={{ width: 13, height: 13 }} src={CloseAdd} />
              </div>
            ) : (
              <div className="close-ads">
                <span style={{ fontWeight: 400, fontSize: 20 }}>{timer}</span>
              </div>
            ))}
          <div
            style={{
              ...(isImageLoaded && {
                backgroundSize: "cover",
                height: "100%",
                width: "100%",
                borderRadius: "35px",
              }),
            }}
          >
            {place?.ads?.random_ad?.media?.advertisement_type === "video" ? (
              <video
                src={place?.ads?.random_ad?.media?.url}
                onLoadedData={handleImageLoad}
                autoPlay
                loop
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "35px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                src={place?.ads?.random_ad?.media?.url}
                onLoad={handleImageLoad}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "35px",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </div>
      ) : place?.ads?.random_ad?.media?.url === null &&
        place?.adsense_code?.length !== 0 ? (
        <div className="ads-modal">
          <span
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "35px",
              objectFit: "cover",
            }}
            dangerouslySetInnerHTML={{
              __html: place?.adsense_code,
            }}
          />
        </div>
      ) : null}
      <div
        style={{
          justifyContent: "space-around",
          paddingTop:
            place.enabled_languages && place.enabled_languages.length < 4
              ? "0px"
              : undefined,
          backdropFilter:
            (isModalOpen &&
              place?.ads?.random_ad !== null &&
              place?.ads?.random_ad?.media !== null) ||
            (place?.ads?.random_ad?.media === null &&
              place?.adsense_code?.length !== 0)
              ? "blur(5px)"
              : "none",
          transition: "backdrop-filter 0.5s ease",
        }}
        className="homeMenu"
      >
        <img src={place?.image} />

        <div
          style={{
            filter:
              (isModalOpen &&
                place?.ads?.random_ad !== null &&
                place?.ads?.random_ad?.media !== null) ||
              (place?.ads?.random_ad?.media === null &&
                place?.adsense_code?.length !== 0)
                ? "blur(1px)"
                : "none",
          }}
          className="languages"
        >
          {sortedLanguages.map((lang) => {
            return (
              enabledPlaceLanguages &&
              enabledPlaceLanguages.includes(lang.langKey) && (
                <div
                  key={lang.langKey}
                  style={{ border: "2px solid white", borderRadius: "16px" }}
                >
                  <button
                    key={lang.langKey}
                    onClick={() => handleClick(lang.langKey)}
                    className="chooseLanguage"
                  >
                    {lang.img}
                    <span style={{ paddingLeft: "10px" }}>{lang.name}</span>
                  </button>
                </div>
              )
            );
          })}
        </div>
        {/* <div
                      style={{
                        width: "82%",
                        height: "80px",
                        borderRadius: "8px",
                        marginBottom: "15px",
                        justifySelf: "center",
                        backgroundColor: "white",
                      }}
                    >
                      <ins
                        className="adsbygoogle"
                        data-ad-client="ca-pub-7408187303742789"
                        data-ad-slot="5411640593"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                      ></ins>
                    </div> */}
        <div
          style={{
            bottom: "0px",
            display: "flex",
            placeContent: "center",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "13px",
              font: "lato, sans-serif",
              background: "transparent",
              padding: "5px 15px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <span
              style={{
                color: showLogin ? "transparent" : "white",
                marginRight: "5px",
              }}
            >
              podrska.ba
            </span>
            {showLogin ? (
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <div className="inputNapomenaDiv">
                  <input
                    placeholder="write password"
                    autoFocus
                    style={{ width: "100%", borderRadius: "25px" }}
                    className="form-control"
                    type="password"
                    value={password}
                    onChange={(e) => onPasswordChange(e)}
                  ></input>
                </div>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                >
                  <MdFileDownloadDone onClick={submitPassword} width={30} />
                </div>
              </div>
            ) : waiterLogged === "true" ? (
              <span onClick={waiterLogout}>©</span>
            ) : (
              <span onClick={showPasswordInput}>©</span>
            )}
            <p className="text-center text-white">v2.1</p>
          </div>
        </div>
      </div>
      {/* <div
          style={{
            width: "300px",
            height: "250px",
            marginBottom: "15px",
            justifySelf: "center",
            backgroundColor: "white",
          }}
        >
          <ins
            className="adsbygoogle"
            data-ad-client="ca-pub-7408187303742789"
            data-ad-slot="6690541941"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div> */}
    </div>
  );
}

